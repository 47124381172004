import styled from 'styled-components'
import {
  gridTemplateColumns,
  gridTemplateRows,
  gridTemplateAreas,
  gridGap,
  justifyContent,
  alignItems,
  height,
} from 'styled-system'
import { Box } from './box.sc'

export const Grid = styled(Box)`
  display: grid;
  ${gridTemplateColumns}
  ${gridTemplateRows}
  ${gridTemplateAreas}
  ${gridGap}
  ${justifyContent}
  ${alignItems}
  ${height}
`
