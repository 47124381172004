import styled from 'styled-components'
import { width, space } from 'styled-system'
import { buttonPreset } from '../styles/variants.sc'

export const Button = styled.button`
  box-sizing: border-box;
  background-color: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
  font-style: outset;
  will-change: transform, background-color, border-color, color;
  transition: transform 150ms ease, background-color 150ms ease,
    border-color 150ms ease, color 150ms ease;
  ${buttonPreset}
  ${width}
  ${space}
`
