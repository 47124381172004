import styled from 'styled-components'
import { color, space, alignItems, justifyContent } from 'styled-system'

export const Card = styled.section`
  box-sizing: border-box;
  background-color: ${props => props.theme.colors.tileBg};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${props => props.theme.space[3]};
  ${alignItems}
  ${justifyContent}
  ${color}
  ${space}
`
