import React from 'react'
import styled from 'styled-components'
import { navigate } from '@reach/router'
import { useLocalStorage } from '../../lib'
import { Button, Card, Text, Input } from '../../components'

const FormRow = styled.div`
  display: flex;
  align-items: center;
  min-width: 18rem;

  input[type='text'] {
    font-size: 1em;
    max-width: 100%;
    background-color: ${props => props.theme.colors.greys(95)};
    border: 1px solid transparent;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    color: white;
    flex: 1 0 auto;

    &:focus {
      border: 1px solid ${props => props.theme.colors.greys(70)};
      outline: none;
    }
  }

  label {
    font-size: 1em;
    margin: 0 0.75em 0 0;
    flex: 0 1 auto;
  }
`

export const Auth = () => {
  const [apiKey, setApiKey] = useLocalStorage('API_KEY')

  function handleChange(event) {
    setApiKey(event.target.value)
  }

  async function handleSave(event) {
    event.preventDefault()
    await navigate('/wallboard')
  }

  // useTransition for loading state

  return (
    <Card alignItems="center" p={6} as="form">
      <Text variant="subheading" mt={0} mb={2} textAlign="center" as="h1">
        Configure
      </Text>
      <Text variant="body" mt={0} mb={4} textAlign="center" as="h1">
        Enter your API key
      </Text>
      <FormRow>
        <Input
          type="text"
          name="bk"
          placeholder="API Key"
          value={apiKey ? apiKey : ''}
          onChange={handleChange}
        />
      </FormRow>

      <Button variant="pill" mt={4} onClick={handleSave}>
        Save
      </Button>
    </Card>
  )
}
