import React from 'react'
import moment from 'moment'
import 'moment-timezone'
import optional from '../../lib/optional'
import { Text, Flex } from '../../components'

const BuildDetail = ({ builds }) => {
  const timeAgo = React.useMemo(() => {
    if (builds && builds.length > 0) {
      return moment
        .tz(
          builds[0].finishedAt ? builds[0].finishedAt : builds[0].startedAt,
          moment.tz.guess()
        )
        .fromNow()
    }
    return ''
  })

  const branch = optional(() => builds[0].branch.split('/').pop()) || 'n/a'

  return (
    <Flex
      alignItems="center"
      flexDirection="column"
      width={'100%'}
      py="1"
      color="grey">
      <Text
        title={branch}
        m={0}
        lineHeight="heading"
        fontWeight="bold"
        css={`
          height: 1.25em;
          overflow: hidden;
        `}>
        Build {builds[0].buildNumber}{' '}
        {branch &&
          `on ${branch.slice(0, 16)}${branch.length > 15 ? '...' : ''}`}
      </Text>
      <Text m={0}>{timeAgo}</Text>
    </Flex>
  )
}

export default BuildDetail
