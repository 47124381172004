import { ColorScale as scale } from '../lib/color-scale'

const colors = {
  primary: '#D92635',
  secondary: '#753EC1',
  accent: '#26D9CA',
  grey: '#857A7C',
}

const scales = {
  primaries: scale(colors.primary),
  secondaries: scale(colors.secondary),
  accents: scale(colors.accent),
  greys: scale(colors.grey),
}

export default {
  ...colors,
  ...scales,
  body: scales.greys(10).hex(),
  link: scales.accents(60).hex(),
  bg: scales.greys(95).hex(),
  tileBg: scales.greys(90).hex(),
  passed: '#35D926',
  failed: colors.primary,
  scheduled: colors.secondary,
  running: '#D2C430',
  cancelled: colors.grey,
  navBg: scales.greys(90),
}
