import { useEffect, useState, useRef } from 'react'
import { getData } from './getData'
import { useInterval } from './useInterval.hook'

const useApiSubscription = (url, options, interval = 60000) => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const urlRef = useRef()

  useEffect(() => {
    urlRef.current = url
  }, [url])

  useEffect(() => {
    getData(urlRef.current, options, setLoading, setError, setData)
  }, [urlRef])

  useInterval(() => {
    if (!error) {
      getData(urlRef.current, options, setLoading, setError, setData)
    }
  }, interval)

  return [loading, error, data]
}

export default useApiSubscription
