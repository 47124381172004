import axios from 'axios'

export async function getData(url, options, setLoading, setError, setData) {
  let result = null
  setLoading(true)

  try {
    result = await axios.get(url, options)
    setData(result.data)
    setError(null)
  } catch (error) {
    console.error(error)
    setError(error)
  }

  setLoading(false)
}
