import React, { useState } from 'react'
import styled from 'styled-components'
import { color, space } from 'styled-system'
import { useSpring, animated, config } from 'react-spring'
import PassedIcon from '../media/img/status-passed.icon.svg'
import RunningIcon from '../media/img/status-running.icon.svg'
import ScheduledIcon from '../media/img/status-scheduled.icon.svg'
import FailedIcon from '../media/img/status-failed.icon.svg'

const Wrapper = styled.div`
  width: 3rem;
  height: 3rem;
  border-radius: 999px;
  ${color}
  ${space}
  flex: 0 0 auto;

  svg {
    width: 100%;
    height: 100%;
  }
`

export const StatusIcon = ({ status }) => {
  let Icon = null
  let bg = null
  let color = 'white'

  const [repeatRotate, setRepeatRotate] = useState(false)
  const spring = useSpring({
    from: { transform: 'rotate(0deg)' },
    to: async next => {
      if (status === 'running') {
        await next({ transform: `rotate(-15deg)` })
        await next({ transform: `rotate(360deg)` })
      } else {
        await next({ transform: 'rotate(0deg)' })
      }
    },
    config: config.gentle,
    reset: repeatRotate,
    onStart: () => setRepeatRotate(false),
    onRest: () =>
      status === 'running' ? setRepeatRotate(true) : setRepeatRotate(false),
  })

  switch (status) {
    case 'passed':
      Icon = PassedIcon
      bg = 'passed'
      break
    case 'scheduled':
      Icon = ScheduledIcon
      bg = 'scheduled'
      break
    case 'running':
      Icon = RunningIcon
      bg = 'running'
      break
    case 'failed':
      Icon = FailedIcon
      bg = 'failed'
      break
    default:
      Icon = null
      bg = 'unknown'
      break
  }

  return (
    <animated.div style={spring}>
      <Wrapper bg={bg} color={color}>
        <Icon />
      </Wrapper>
    </animated.div>
  )
}
