import { variant } from 'styled-system'

export const textPreset = variant({
  key: 'textPresets',
})

export const buttonPreset = variant({
  key: 'buttonPresets',
})

export const avatarPreset = variant({
  key: 'avatarPresets',
})
