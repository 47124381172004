import React from 'react'
import { ThemeProvider } from 'styled-components'
import GlobalStyle from './styles/global.sc'
import Theme from './theme'
import Routes from './pages/routes'

const App = () => {
  return (
    <ThemeProvider theme={Theme}>
      <>
        <Routes />
        <GlobalStyle />
      </>
    </ThemeProvider>
  )
}

export default App
