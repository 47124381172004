import styled from 'styled-components'
import {
  color,
  space,
  fontWeight,
  fontFamily,
  textAlign,
  lineHeight,
} from 'styled-system'
import { textPreset } from '../styles/variants.sc'

export const Text = styled.p`
  ${textPreset}
  ${color}
  ${space}
  ${fontWeight}
  ${fontFamily}
  ${textAlign}
  ${lineHeight}

  strong {
    font-weight: ${props =>
      parseInt(props.theme.textPresets[props.variant].fontWeight, 10) + 100}
  }

  em {
    font-style: italic;
  }
`

Text.defaultProps = {
  variant: 'body',
}
