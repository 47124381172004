import React from 'react'
import styled from 'styled-components'
import { gridArea, alignSelf, display, border } from 'styled-system'
import { Grid, Box } from '../components'

const Wrapper = styled(Box)`
  ${gridArea}
  ${alignSelf}
  ${display}
  ${border}
`

export const DashboardLayout = ({ children }) => {
  const [projects, praise] = React.Children.toArray(children)
  return (
    <>
      <Grid
        gridTemplateColumns={['1fr', '1fr', 'auto 30em']}
        gridTemplateRows={['auto', 'auto', 'auto 4rem']}
        gridTemplateAreas={[
          `"projects"
          "praise"`,
          `"projects"
          "praise"`,
          '"projects praise"',
        ]}
        height={'100vh'}>
        <Wrapper gridArea={'projects'}>{projects}</Wrapper>
        <Wrapper gridArea={'praise'} display={'flex'}>
          {praise}
        </Wrapper>
      </Grid>
    </>
  )
}
