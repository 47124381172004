import styled from 'styled-components'
import {
  flexDirection,
  justifyContent,
  alignItems,
  flexWrap,
  order,
} from 'styled-system'
import { Box } from './box.sc'

export const Flex = styled(Box)`
  display: flex;
  ${flexDirection}
  ${justifyContent}
  ${alignItems}
  ${flexWrap}
  ${order}
`
