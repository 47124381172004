import typography from './type'
import colors from './colors'
import space from './space'
import radii from './radii'

const sizes = {
  small: {
    fontSize: typography.fontSizes[1],
    lineHeight: typography.lineHeights.heading,
    fontWeight: typography.fontWeights.bold,
    paddingTop: space[1],
    paddingRight: space[2],
    paddingBottom: space[1],
    paddingLeft: space[2],
    borderRadius: radii.radii[1],
  },
  normal: {
    fontSize: typography.fontSizes[2],
    lineHeight: typography.lineHeights.heading,
    fontWeight: typography.fontWeights.semiBold,
    paddingTop: space[2],
    paddingRight: space[3],
    paddingBottom: space[2],
    paddingLeft: space[3],
    borderRadius: radii.radii[2],
  },
  large: {
    fontSize: typography.fontSizes[3],
    lineHeight: typography.lineHeights.heading,
    fontWeight: typography.fontWeights.medium,
    paddingTop: space[3],
    paddingRight: space[5],
    paddingBottom: space[3],
    paddingLeft: space[5],
    borderRadius: radii.radii[3],
  },
}

const hoverScaleFactor = 'scale(0.99)'
const activeScaleFactor = 'scale(0.97)'

const types = {
  primary: {
    backgroundColor: colors.accents(50),
    color: colors.greys(0),
    '&:hover': {
      backgroundColor: colors.accents(60),
      transform: hoverScaleFactor,
    },
    '&:active': {
      transform: activeScaleFactor,
    },
    '&:focus': {
      outline: 'none',
      borderColor: colors.accents(40),
    },
  },
  secondary: {
    backgroundColor: colors.primaries(50),
    color: colors.primaries(0),
    '&:hover': {
      backgroundColor: colors.primaries(60),
      transform: hoverScaleFactor,
    },
    '&:active': {
      transform: activeScaleFactor,
    },
    '&:focus': {
      outline: 'none',
      borderColor: colors.primaries(40),
    },
  },
  tertiary: {
    backgroundColor: colors.secondaries(50),
    color: colors.secondaries(0),
    '&:hover': {
      backgroundColor: colors.secondaries(60),
      transform: hoverScaleFactor,
    },
    '&:active': {
      transform: activeScaleFactor,
    },
    '&:focus': {
      outline: 'none',
      borderColor: colors.secondaries(40),
    },
  },

  pill: {
    backgroundColor: colors.secondaries(50),
    color: colors.secondaries(0),
    width: 'auto',
    '&:hover': {
      backgroundColor: colors.secondaries(60),
      transform: hoverScaleFactor,
    },
    '&:active': {
      transform: activeScaleFactor,
    },
    '&:focus': {
      outline: 'none',
      borderColor: colors.secondaries(40),
    },
  },
}

export default {
  buttonPresets: {
    primary: {
      ...sizes.normal,
      ...types.primary,
      small: {
        ...sizes.small,
        ...types.primary,
      },
      normal: {
        ...sizes.normal,
        ...types.primary,
      },
      large: {
        ...sizes.large,
        ...types.primary,
      },
    },
    secondary: {
      ...sizes.normal,
      ...types.secondary,
      small: {
        ...sizes.small,
        ...types.secondary,
      },
      normal: {
        ...sizes.normal,
        ...types.secondary,
      },
      large: {
        ...sizes.large,
        ...types.secondary,
      },
    },
    tertiary: {
      ...sizes.normal,
      ...types.tertiary,
      small: {
        ...sizes.small,
        ...types.tertiary,
      },
      normal: {
        ...sizes.normal,
        ...types.tertiary,
      },
      large: {
        ...sizes.large,
        ...types.tertiary,
      },
    },
    pill: {
      ...sizes.normal,
      ...types.pill,
      borderRadius: '999px',
      width: 'auto',
      lineHeight: typography.lineHeights.copy,
      paddingLeft: space[5],
      paddingRight: space[5],
    },
  },
}
