const fontSizes = [
  '0.5rem',
  '0.75rem',
  '1rem',
  '1.5rem',
  '2rem',
  '3rem',
  '5rem',
]

const lineHeights = {
  solid: '1',
  largeHeading: '1.1',
  heading: '1.25',
  copy: '1.5',
}

const fontWeights = {
  thin: '100',
  extraLight: '200',
  light: '300',
  regular: '400',
  medium: '500',
  semiBold: '600',
  bold: '700',
  extraBold: '800',
  black: '900',
}

const letterSpacing = {
  tracked: '0.1em',
  mega: '0.15em',
}

const measure = {
  normal: '30em',
}

const fonts = {
  body: "'Inter'",
  headings: "'Inter'",
}

export default {
  fonts,
  fontSizes,
  lineHeights,
  fontWeights,
  letterSpacing,
  measure,
  textPresets: {
    display: {
      fontSize: fontSizes[5],
      lineHeight: lineHeights.largeHeading,
      fontWeight: fontWeights.medium,
    },
    heading: {
      fontSize: fontSizes[4],
      lineHeight: lineHeights.heading,
      fontWeight: fontWeights.semiBold,
    },
    subheading: {
      fontSize: fontSizes[3],
      lineHeight: lineHeights.heading,
      fontWeight: fontWeights.bold,
    },
    eyebrow: {
      fontSize: fontSizes[2],
      lineHeight: lineHeights.heading,
      textTransform: 'uppercase',
      letterSpacing: letterSpacing.tracked,
      fontWeight: fontWeights.extraBold,
    },
    body: {
      fontSize: fontSizes[2],
      lineHeight: lineHeights.copy,
      fontWeight: fontWeights.regular,
    },
    caption: {
      fontSize: fontSizes[1],
      lineHeight: lineHeights.heading,
      fontWeight: fontWeights.medium,
    },
  },
}
