import React from 'react'
import { Flex, Text } from '../../components'

export const FatalError = ({ error }) => {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      p={5}>
      <Text as={'h1'} my={5} variant={'subheading'} textAlign="center">
        {error.message}
      </Text>
      <code
        css={`
          background-color: #222;
          color: #bbb;
          padding: 1rem 2rem;
        `}>
        {error.stack}
      </code>
    </Flex>
  )
}
