import React from 'react'
import { Flex } from '../components'

export const CenteredLayout = ({ children }) => (
  <Flex
    justifyContent="center"
    alignItems="center"
    css={`
      height: 100vh;
    `}>
    {children}
  </Flex>
)
