import React from 'react'
import styled from 'styled-components'
import { Text, Button } from '../../components/'
import { navigate } from '@reach/router'

const Nav = styled.nav`
  box-sizing: border-box;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid ${props => props.theme.colors.greys(80)};
  padding: 0 ${props => props.theme.space[3]};
  height: 4rem;
`

export const Navbar = () => {
  return (
    <Nav>
      <Button variant={'tertiary'} onClick={() => navigate('/')}>
        Config
      </Button>
      <Text m={0} variant={'eyebrow'}>
        Wallboard v2.0
      </Text>
    </Nav>
  )
}

export default Navbar
