import React from 'react'
import { Router } from '@reach/router'
import { Auth, Dashboard, FatalError } from '.'
import { CoreLayout, CenteredLayout } from '../layouts'
import { ErrorBoundary, Navbar } from '../components'

const AuthRoute = () => (
  <CenteredLayout>
    <Auth />
  </CenteredLayout>
)

const DashboardRoute = () => {
  return (
    <ErrorBoundary fallback={<FatalError />}>
      <CoreLayout>
        <Navbar />
        <Dashboard />
      </CoreLayout>
    </ErrorBoundary>
  )
}

const Routes = () => (
  <Router>
    <AuthRoute path="/" default />
    <DashboardRoute path="/wallboard" />
  </Router>
)

export default Routes
