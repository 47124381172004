import React from 'react'
import styled from 'styled-components'
import BuildkiteIcon from '../media/img/service-logo-buildkite.icon.svg'
import BitriseIcon from '../media/img/service-logo-bitrise.icon.svg'
import AzureIcon from '../media/img/service-logo-azure.icon.svg'

const Wrapper = styled.div`
  position: absolute;
  top: 1rem;
  left: 1rem;
  width: 2rem;
  height: 2rem;
  color: ${props => props.theme.colors.greys(80)};

  svg {
    width: 100%;
    height: 100%;
  }
`

export const ServiceIcon = props => {
  let Icon = null
  switch (props.service) {
    case 'buildkite':
      Icon = BuildkiteIcon
      break
    case 'bitrise':
      Icon = BitriseIcon
      break
    case 'azure':
      Icon = AzureIcon
      break
    default:
      Icon = null
  }
  return (
    <Wrapper>
      <Icon />
    </Wrapper>
  )
}
