import React from 'react'
import styled from 'styled-components'
import { gridArea, alignSelf, display, border } from 'styled-system'
import { Grid, Box } from '../components'

const Wrapper = styled(Box)`
  ${gridArea}
  ${alignSelf}
  ${display}
  ${border}
`

export const CoreLayout = ({ children }) => {
  const [nav, dashboard] = React.Children.toArray(children)
  return (
    <>
      <Grid
        gridTemplateColumns={'auto'}
        gridTemplateRows={'auto'}
        gridTemplateAreas={'"wallboard"'}
        height={'100vh'}>
        <Wrapper gridArea={'wallboard'}>{dashboard}</Wrapper>
      </Grid>

      <Wrapper
        display={'flex'}
        bg={'navBg'}
        gridArea={'nav'}
        alignSelf={'stretch'}
        css={`
          position: fixed;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: 20;
        `}>
        {nav}
      </Wrapper>
    </>
  )
}

export default CoreLayout
