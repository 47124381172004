import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  @import url('https://rsms.me/inter/inter.css');
  @supports (font-variation-settings: normal) {
    html { font-family: 'Inter var', sans-serif; }
  }

  body {
    scroll-behavior: smooth;
    background-color: ${props => props.theme.colors.bg};
    color: ${props => props.theme.colors.body};
    margin: 0;
    padding: 0;
    font-family: ${props =>
      props.theme.fonts.body || ''}, -apple-system, BlinkMacSystemFont,
      'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
      'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: 100vh;
    line-height: 1.5;
  }

  a {
    color: ${props => props.theme.colors.link};
  }

  * {
    ${'' /* outline: 1px solid red; */}
  }

  @media screen and (min-width: 3000px) {
    html {
      font-size: 20px;
    }
  }

  /* Hide scrollbars in Chrome/Chromium */
  ::-webkit-scrollbar {
      width: 0px;
      background: transparent;
  }
  ::-webkit-scrollbar-thumb {
      background: transparent;
  }
`
