import React from 'react'
import styled from 'styled-components'
import {
  Text,
  Grid,
  Box,
  ErrorBoundary,
  BuildHistory,
  ServiceIcon,
  Tile,
} from '../../components'
import ProjectStatus from './project-status'
import BuildDetail from './build-detail'

const Wrapper = styled(Box)`
  box-sizing: border-box;
  overflow: scroll;
  max-height: 100vh;
`

const ProjectTile = ({ name, service, builds }) => {
  const buildHistory = builds.slice(0, 5).map(build => build.status)
  return (
    <ErrorBoundary fallback={`Error for project ${name}`}>
      <Tile p={5}>
        <ServiceIcon service={service} />
        <Text
          as={'h1'}
          my={4}
          variant={'subheading'}
          textAlign="center"
          css={`
            height: 2.5em;
            overflow: hidden;
          `}>
          {`${name}`}
        </Text>
        {builds && builds.length > 0 && (
          <>
            <ProjectStatus builds={builds} />
            <BuildDetail builds={builds} />
            <BuildHistory buildHistory={buildHistory} />
          </>
        )}
      </Tile>
    </ErrorBoundary>
  )
}

export const Projects = ({ projects }) => {
  return (
    <ErrorBoundary fallback={'Error in Wallboard'}>
      <Wrapper p={4} pb="5rem">
        <Text my={3} textAlign={'center'} variant={'eyebrow'}>
          Continuous Integration
        </Text>
        <ErrorBoundary fallback={'Error in Grid'}>
          <Grid
            gridTemplateColumns={'repeat(auto-fit, minmax(20em, 1fr))'}
            gridGap={'0.125rem'}>
            {projects &&
              Array.isArray(projects) &&
              projects.map(project => (
                <ProjectTile key={project.id} {...project} />
              ))}
          </Grid>
        </ErrorBoundary>
      </Wrapper>
    </ErrorBoundary>
  )
}
