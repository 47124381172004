import React from 'react'
import styled, { keyframes } from 'styled-components'
import { color } from 'styled-system'

const blink = keyframes`
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`

const Build = styled.span`
  width: ${props => props.theme.space[2]};
  height: ${props => props.theme.space[2]};
  border-radius: 999px;
  display: inline-block;
  margin: 0 ${props => props.theme.space[1]};
  background-color: ${props => props.theme.colors[props.status]};
  animation: ${blink} 2s infinite
    ${props =>
      props.status === 'running' || props.status === 'scheduled'
        ? 'running'
        : 'paused'};
  ${color}
`

export const BuildHistory = ({ buildHistory }) => {
  return (
    <div
      css={`
        text-align: center;
        margin: 1rem 0 0;
      `}>
      {buildHistory.reverse().map((status, index) => (
        <Build
          key={`${index}-${status}`}
          data-status={status}
          status={status}
        />
      ))}
    </div>
  )
}
