import React from 'react'
import styled from 'styled-components'
import { color, space } from 'styled-system'

const Wrapper = styled.section`
  position: relative;
  padding-bottom: 100%;

  > div {
    background-color: ${props => props.theme.colors.tileBg};
    color: white;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    padding: ${props => props.theme.space[3]};
    ${color}
    ${space}
  }
`

export const Tile = props => {
  return (
    <Wrapper {...props}>
      <div>{props.children}</div>
    </Wrapper>
  )
}
