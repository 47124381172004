import React from 'react'
import { Flex, StatusIcon } from '../../components'

const ProjectStatus = ({ builds }) => {
  if (!builds) return null

  return (
    <Flex order="-1" justifyContent="center">
      <StatusIcon status={builds[0].status} />
    </Flex>
  )
}

export default ProjectStatus
