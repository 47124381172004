import { useState, useEffect, useRef } from 'react'

const myStorage = window.localStorage

export function useLocalStorage(key) {
  // Keep a copy of the storage key we started with
  const keyRef = useRef(key)

  const [value, setValue] = useState(
    JSON.parse(myStorage.getItem(keyRef.current))
  )

  // If value is changed via setValue,
  // set it in storage
  useEffect(() => {
    myStorage.setItem(keyRef.current, JSON.stringify(value))
  }, [value])

  // Return the latest value,
  // and a setter
  return [value, setValue]
}
