import colors from './colors'
import typography from './type'
import buttons from './buttons'
import space from './space'
import radii from './radii'

const widths = [0, '1rem', '2rem', '3rem', '5rem', '8rem']
const heights = [0, '1rem', '2rem', '3rem', '5rem', '8rem']

export default {
  colors: {
    ...colors,
  },
  ...typography,
  ...buttons,
  space: [...space],
  ...radii,
  widths,
  heights,
  avatarPresets: {
    small: {
      width: widths[2],
      height: heights[2],
    },
    normal: {
      width: widths[3],
      height: heights[3],
    },
    large: {
      width: widths[4],
      height: heights[4],
    },
  },
}
