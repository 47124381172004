import React from 'react'
import { Flex, Text } from './'

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false, error: null }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    console.error(error)
    return { hasError: true, error }
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    console.error(error, info)
  }

  render() {
    const { fallback: Fallback } = this.props
    if (this.props.fallback && this.state.hasError) {
      // You can render any custom fallback UI
      return typeof Fallback === 'string' ? (
        <Flex justifyContent="center" alignItems="center">
          <Text textAlign="center">{Fallback}</Text>
        </Flex>
      ) : (
        <Fallback error={this.state.error} />
      )
    }

    if (this.state.hasError) {
      return null
    }

    return this.props.children
  }
}

export default ErrorBoundary
