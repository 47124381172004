export default function optional(cb) {
  try {
    return cb()
  } catch (e) {
    if (e instanceof TypeError) {
      console.error(e)
      return null
    } else throw e
  }
}
