import styled from 'styled-components'
import { space, color, borderRadius, zIndex } from 'styled-system'
import { avatarPreset } from '../styles/variants.sc'
import { Flex } from './flex.sc'

export const Avatar = styled.img`
  border-radius: ${props => props.theme.radiusPill};
  overflow: hidden;
  ${space}
  ${color}
  ${borderRadius}
  ${avatarPreset}
  ${zIndex}
`

export const Facepile = styled(Flex)`
  flex-wrap: wrap;
  img {
    border: 3px solid ${props => props.theme.colors.tileBg};
  }
  img:not(:first-child) {
    margin-left: -${props => props.theme.space[4]};
  }
`

export const Facecount = styled(Flex)`
  border-radius: ${props => props.theme.radiusPill};
  border: 3px solid ${props => props.theme.colors.tileBg};
  margin-left: ${props => props.theme.space[2]};
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.colors.greys(50)};
  ${avatarPreset}
`
