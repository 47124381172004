import React, { useState, useEffect } from 'react'
import { DashboardLayout, CenteredLayout } from '../../layouts'
import { Praise, Projects } from './'
import { useLocalStorage } from '../../lib/useLocalStorage.hook'
import useApiSubscription from '../../lib/useApiSubscription.hook'
import { FatalError } from '../error'

const url = process.env.API_URL_PROD
const options = key => ({
  headers: {
    Authorization: `Bearer ${key}`,
  },
})

const Dash = ({ dashData }) => (
  <DashboardLayout>
    <Projects projects={dashData.wallboard} />
    <Praise praise={dashData.praise} />
  </DashboardLayout>
)

export const Dashboard = () => {
  const [interval] = useState(30000)
  const [apiKey] = useLocalStorage('API_KEY')
  const [dashboard, setDashboard] = useLocalStorage('dashboard')
  const [loading, error, data] = useApiSubscription(
    url,
    options(apiKey),
    interval
  )

  useEffect(() => {
    if (data) {
      setDashboard(data)
    }
  }, [data])

  // If theres an error
  // And we're not fetching new shit
  // And there's no data to display
  if (error && !loading && !data) {
    return <FatalError error={error} />
  }

  // If there's no fresh data
  // and we're loading fresh data
  // and we have some data in localStorage
  if (!data && loading && dashboard) {
    return <Dash dashData={dashboard} />
  }

  // If we don't have any data
  // and we're loading some fresh stuff
  if (!data && loading) {
    return <CenteredLayout>Loading...</CenteredLayout>
  }

  // If we have data
  // and our objects are sweet
  if (data && data.wallboard && data.praise) {
    return <Dash dashData={data} />
  }

  // Byeeeee
  return null
}
