import React from 'react'
import styled from 'styled-components'

const Switch = styled.label`
  display: block;
  width: 2em;
  height: 1em;
  position: relative;
  background-color: ${props => props.theme.colors.greys(50)};
  border-radius: 4px;

  &::before {
    pointer-events: none;
    box-sizing: border-box;
    content: '';
    line-height: 1em;
    width: calc(1em - 2px);
    height: calc(1em - 2px);
    position: absolute;
    left: 1px;
    top: 1px;
    border-radius: 3px;
    background-color: ${props => props.theme.colors.greys(90)};
    border: 1px solid transparent;
    transition: transform 100ms ease, background-color 100ms linear;
  }

  input:checked + & {
    background-color: ${props => props.theme.colors.passed};
  }

  input:checked + &::before {
    transform: translateX(calc(1em - 1px));
  }
`

export const Toggle = ({ name, onChange, checked, ...rest }) => (
  <span>
    <input
      type="checkbox"
      id={name}
      onChange={onChange}
      checked={checked ? true : false}
      {...rest}
      css={`
        display: none;
      `}
    />
    <Switch htmlFor={name} />
  </span>
)
