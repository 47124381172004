import React from 'react'
import ReactDOM from 'react-dom'
import App from './app'

ReactDOM.render(<App />, document.getElementById('app'))
// Future: concurrent React mode
// ReactDOM.createRoot(document.getElementById('app')).render(<App />);

if (module.hot) {
  module.hot.accept()
}
