import React from 'react'
import styled from 'styled-components'
import {
  Text,
  Grid,
  Card,
  Avatar,
  Facepile,
  Facecount,
  Flex,
  ErrorBoundary,
} from '../../components'

const Wrapper = styled(Flex)`
  box-sizing: border-box;
  border-left: 1px solid ${props => props.theme.colors.greys(80)};
  flex-direction: column;
  overflow: scroll;
  max-height: 100vh;
  width: 100%;
  position: relative;
`

const NameList = styled(Flex)`
  span:not(:first-child)::before {
    content: '\\00A0';
  }
`

const PraiseCard = ({ praise }) => {
  let recipients = praise.recipients.map(r => r)
  let truncated = false
  if (recipients.length > 7) {
    truncated = true
    recipients = recipients.slice(0, 5)
  }

  return (
    <Card px={4} py={5} borderRadius={'radii[3]'}>
      <ErrorBoundary fallback="Error in Praise Card">
        <Facepile>
          {recipients.map((recipient, index) => (
            <Avatar
              key={recipient.name}
              variant={'normal'}
              src={`https://placehold.it/128x128/000000/FFFFFF?text=${recipient.firstName.slice(
                0,
                1
              )}${recipient.lastName && recipient.lastName.slice(0, 1)}`}
              alt={`${recipient.name}'s avatar`}
              zIndex={praise.recipients.length - 1 - index}
            />
          ))}
          {truncated && (
            <Facecount variant={'normal'}>
              <Text
                variant={'caption'}
                textAlign={'center'}
                lineHeight={'solid'}>
                + {praise.recipients.length - 6} more
              </Text>
            </Facecount>
          )}
        </Facepile>
        <NameList flexWrap={'wrap'} justifyContent={'center'} mt={2} mb={4}>
          {praise.recipients.map((recipient, index) => (
            <Text key={recipient.name} variant={'caption'} as={'span'}>
              {recipient.firstName}
              {index !== praise.recipients.length - 1 && ','}
            </Text>
          ))}
        </NameList>
        {/* fixme: yuck */}
        <Text
          variant={'body'}
          as={'div'}
          my={0}
          css={`
            p {
              margin-top: 0;
            }
          `}
          dangerouslySetInnerHTML={{ __html: praise.message }}
        />
        <Text variant={'caption'}>Praise by {praise.author.name}</Text>
      </ErrorBoundary>
    </Card>
  )
}

export const Praise = ({ praise }) => {
  if (praise)
    return (
      <ErrorBoundary fallback="Error in Praise List">
        <Wrapper p={4} pb="5rem">
          <Text my={3} textAlign={'center'} variant={'eyebrow'}>
            Team Praise
          </Text>
          <Grid gridTemplateColumns={'1fr'} gridGap={'0.125rem'}>
            {praise.map(praise => (
              <PraiseCard key={praise.id} praise={praise} />
            ))}
          </Grid>
        </Wrapper>
      </ErrorBoundary>
    )
  return null
}
