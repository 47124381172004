import styled from 'styled-components'
import { color, space, width, display } from 'styled-system'
import { textPreset } from '../styles/variants.sc'

export const Input = styled.input`
  appearance: none;
  border: none;
  background-color: ${props => props.theme.colors.greys(10)};
  &::placeholder {
    color: ${props => props.theme.colors.greys(50)};
  }
  &:focus,
  &:hover {
    background-color: ${props => props.theme.colors.greys(15)};
    outline: none;
  }
  border-radius: ${props => props.theme.radii[2]};
  color: ${props => props.theme.colors.greys(90)};
  padding: ${props => props.theme.space[2]} ${props => props.theme.space[4]};
  ${color}
  ${space}
  ${textPreset}
  ${width}
  ${display}
`

Input.defaultProps = {
  variant: 'body',
}
